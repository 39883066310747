<template>
  <div data-pagearea="category_filter">

    <!-- active filter -->

    <template v-if="!props.aside && activeFilter.length">
      <TransitionGroup name="list" tag="div">
        <button v-for="item in activeFilter" :key="item.urlValue" @click="filterApplied(item.urlParameter, item.urlValue, true, false)" class="inline-flex gap-4 pr-3 items-center mr-2 mb-2 text-xs border border-neutral-200 bg-neutral-200" :title="i18n.t('Kliknutím zrušíte hodnotu filtru')">
          <div class="px-4 py-2 uppercase bg-neutral-50">{{ item.label }}</div>
          <strong :class="item.class">{{ item.text }}</strong>
          <AtomsImagesIcon icon="cross" size="lg" class="scale-50" />
        </button>
      </TransitionGroup>
      <div class="flex justify-between mt-2 mb-2">
        <span class="uppercase">{{ $t('n produktů', props.filteredItemsCount ?? 0) }}</span>
        <button class="font-bold uppercase underline neutral-400" @click="clearFilter">{{ $t('Zrušit vybrané parametry') }}</button>
      </div>
    </template>

    <!-- filter controls -->

    <div v-if="props.aside" class="warelist__filter">
      <div v-if="props.withHeading ?? true" class="pb-8 mb-8 pt-4 border-b border-neutral-200 text-3xl font-bold desktop:text-center uppercase">{{ $t('Filtr') }}</div>
      <template v-for="item in filterItems">
        <MoleculesBlocksWareListFilterBlock @apply-filter="filterApplied" v-if="item.type !== 'filtergroup'" :label="item.label" :url-parameter="item.urlParameter" :min-value="item.minValue" :max-value="item.maxValue" :min-range="item.minRange" :max-range="item.maxRange" :unit="item.unit" :with-search="item.withSearch" :options="item.options" />
      </template>
      <div class="text-center">
        <MoleculesButtonsButton @click="showWaresClicked">{{ $t('Zobrazit produkty') }}</MoleculesButtonsButton>
      </div>
    </div>
  </div>
</template>

<script setup>

const props = defineProps({
  fullProperties: Object,
  fullTags: Object,
  filteredProperties: Object,
  filteredTags: Object,
  filteredItemsCount: Number,
  modelValue: Object,
  aside: Boolean,
  staticFilter: Object,
  withHeading: Boolean
});

const emit = defineEmits(['update:modelValue', 'scrollToWareList']);

const appConfig = useAppConfig();
const i18n = useI18n();
const utils = useUtils();
const shopRouting = useShopRouting();
const route = useRoute();
const locale = useLocale().getLocale();

const filterItems = ref([]);

const hash = ref(shopRouting.parseHash());

// values that should not be displayed in the filter
const suppressedLValues = [
  appConfig.parameterlValues.label_sale // sale is determined and shown by the tag
];

const definedTags = [
  {
    tagType: 'ACTION',
    text: i18n.t('Akce'),
    urlParameter: 'tag',
    urlValue: 'sale',
  },
  {
    tagType: 'CLEARANCESALE',
    text: i18n.t('Výprodej'),
    urlParameter: 'tag',
    urlValue: 'clearance',
  },
  {
    tagType: 'NEW',
    text: i18n.t('Novinka'),
    urlParameter: 'tag',
    urlValue: 'news',
  },
];

const buildBasicFilter = (minRange, maxRange) => {

  const result = [];

  result.push({
    label: i18n.t('Dostupnost'),
    type: 'tag',
    urlParameter: 'stores',
    options: appConfig.suppliers.filter(supplier => supplier.wareListFilterLabel).map(supplier => {
      return {
        tagType: 'STORED',
        tagValue: supplier.supplierId,
        text: i18n.t(supplier.wareListFilterLabel),
        urlValue: supplier.supplierId.toString(),
      }
    }),
    position: 10003,
  });

  result.push({
    label: i18n.t('Cena'),
    type: 'price',
    urlParameter: 'price',
    minValue: minRange,
    maxValue: maxRange,
    minRange: minRange,
    maxRange: maxRange,
    unit: locale.currency.signLocal,
    position: 10001
  });

  return result;
}

const buildParametricFilter = (properties) => {

  let result = properties
    // remove items without valid options or with filter groups
    .filter((item) => item.type !== 'FILTERGROUP' && ((!!item.values?.find(q => q.value) && item.values.some(q => !suppressedLValues.includes(q.lValue))) || (item.minimalMiminalValue || item.maximalMaximalValue)))
    .map((item) => {

      const urlParameter = utils.slugify(item.name);

      const itemResult = {
        label: item.name?.indexOf(':') > -1 ? item.name.substring(item.name.indexOf(':') + 1).trim() : item.name,
        type: item.id === appConfig.parameterIds.label ? 'label' : 'property',
        urlParameter,
        position: item.id === appConfig.parameterIds.label ? 10002 : item.priority,
        minValue: item.minimalMaximalValue,
        maxValue: item.maximalMaximalValue,
        minRange: item.minimalMaximalValue,
        maxRange: item.maximalMaximalValue,
        unit: item.unit,
        withSearch: item.id === appConfig.parameterIds.vendor,
        idParameter: item.id,
        options: item.values
          // remove options without valid values
          ?.filter((option) => {
            return !!option.value && !suppressedLValues.includes(option.lValue);
          })
          .map((option) => {

            const urlValue = utils.slugify(option.value);

            return {
              text: option.value,
              lValue: option.lValue,
              idParameter: item.id,
              flag: option.tags.find(tag => tag.type === 'MANUFACTURERCOUNTRY')?.value,
              urlValue,
              preferred: option.preferred,
            }
          })
          .sort((a, b) => {

            // at first sort by preferred, then by text

            if (a.preferred && !b.preferred) return -1;
            if (!a.preferred && b.preferred) return 1;

            const clearA = a.text.trim().toLowerCase();
            const clearB = b.text.trim().toLowerCase();

            return clearA.localeCompare(clearB);
          })
      };

      return itemResult;
    });

  // transform filter group properties to filter items

  const filterGroupProperties = properties.filter((item) => item.type === 'FILTERGROUP').flatMap((item) => {
    return item.values.flatMap(option => {
      return option.filters.flatMap(filterGroup => {

        const urlValue = utils.slugify(filterGroup.name);

        return {
          label: i18n.t('Model'),
          type: 'filtergroup',
          urlParameter: `fg-${filterGroup.parent.id}`,
          idParameter: item.id,
          position: -1,
          options: [
            {
              text: [filterGroup.parent.name, filterGroup.name].join(' '),
              lValue: option.lValue,
              urlValue
            }]
        };
      });
    })
  });

  // merge filter group properties by urlParameter

  const mergedFilterGroupProperties = filterGroupProperties.reduce((acc, item) => {

    const existingItem = acc.find(q => q.urlParameter === item.urlParameter);

    if (existingItem) {
      existingItem.options = existingItem.options.concat(item.options);
    }
    else {
      acc.push(item);
    }

    return acc;
  }, []);

  result = result.concat(mergedFilterGroupProperties);

  return result;
}

const mergeParametricFilterWithTags = (filter, filterTags) => {

  // add defined tags that are present in the input (prop) filter as the values of the label type filter
  filterTags.forEach((propTag) => {

    const definedTag = definedTags.find(tag => tag.tagType === propTag.type);

    if (definedTag) {

      let filterBlockType = 'label';

      // add sale and clearance tags to price filter that is already present in the filter
      if (definedTag.tagType === 'ACTION' || definedTag.tagType === 'CLEARANCESALE') {
        filterBlockType = 'price';
      }

      // if there is no label type filter, add it to include defined tags
      else if (definedTag.tagType !== 'STORED' && !filter.find(item => item.type === filterBlockType)) {
        filter.push({
          label: i18n.t('Označení'),
          type: 'label',
          urlParameter: 'label',
          position: 10002,
          options: []
        });
      }

      // if there is no option with the same urlValue, add it
      const filterBlockObject = filter.find(item => item.type === filterBlockType);

      if (!filterBlockObject.options) {
        filterBlockObject.options = [];
      }

      if (!filterBlockObject.options.find(option => option.urlValue === definedTag.urlValue)) {
        filterBlockObject.options.push({
          tagType: propTag.type,
          tagValue: propTag.value,
          text: definedTag.text,
          urlParameter: definedTag.urlParameter,
          urlValue: definedTag.urlValue,
        });
      }
    }
  });
}

const buildFilter = () => {

  const basicFilter = buildBasicFilter(props.fullProperties.minPrice.price, props.fullProperties.maxPrice.price);
  const parametricFilter = buildParametricFilter(props.fullProperties.properties);

  const result = basicFilter.concat(parametricFilter);

  mergeParametricFilterWithTags(result, props.fullTags);

  result.sort((a, b) => b.position - a.position);

  return result;
}

// synchronizes filter controls with parameters coming from hash or filtered filter data
const updateFilterItems = (availableValues) => {

  filterItems.value.forEach((item) => {

    if (item.options && item.options.length > 0) {
      item.options.forEach((option) => {
        option.selected = isFilterValueInHash(option.urlParameter ?? item.urlParameter, option.urlValue);
        option.unavailable = option.lValue && availableValues && !availableValues.includes(option.lValue);
      });
    }

    if (item.minRange || item.maxRange) {
      const selectedRange = hash.value.find(q => q.key === item.urlParameter)?.value[0];

      if (selectedRange && selectedRange.indexOf('-') > -1) {
        const rangeValues = selectedRange.split('-');

        if (rangeValues.length == 2) {
          item.minValue = parseInt(rangeValues[0]);
          item.maxValue = parseInt(rangeValues[1]);
        }
      }
      else {
        item.minValue = item.minRange;
        item.maxValue = item.maxRange;
      }
    }
  });

  emitConditionalTree();
}

const emitConditionalTree = () => {

  const result = {};
  const allGroups = [];

  filterItems.value.forEach(filterItem => {

    const combinationGroup = [];

    filterItem.options?.filter(option => option.selected).forEach(optionItem => {

      if (optionItem.lValue) {
        combinationGroup.push({
          properties: [
            {
              idParameter: optionItem.idParameter,
              lValue: {
                eq: optionItem.lValue
              }
            }
          ]
        });
      }
      else if (optionItem.tagType) {
        combinationGroup.push({
          tags: [
            {
              type: optionItem.tagType,
              value: optionItem.tagValue?.toString()
            }
          ]
        });
      }
    });

    if (filterItem.minValue > filterItem.minRange || filterItem.maxValue < filterItem.maxRange) {
      if (filterItem.type === 'price') {
        combinationGroup.push({
          price: {
            gte: filterItem.minValue,
            lte: filterItem.maxValue
          }
        });
      }
      else {
        combinationGroup.push({
          properties: [
            {
              idParameter: filterItem.idParameter,
              numberMinValue: {
                gte: filterItem.minValue,
                lte: filterItem.maxValue
              },
              numberMaxValue: {
                gte: filterItem.minValue,
                lte: filterItem.maxValue
              },
            }
          ]
        });
      }
    }

    if (combinationGroup.length > 0) {
      allGroups.push(combinationGroup);
    }
  });

  if (props.aside && !allGroups.length) {
    useBaseAnalytics().pushEvent('user_interaction', {
      interaction_name: 'filter_clear'
    });
  }

  Object.keys(props.staticFilter).forEach(key => {
    allGroups.push([{ [key]: props.staticFilter[key] }]);
  });

  const andTrees = [];

  let allCombinations = cartesianProduct(allGroups);

  allCombinations.forEach(combination => {
    const andTree = getConditionalTree('and', combination);
    andTrees.push(andTree);
  });

  if (andTrees.length) {
    Object.assign(result, getConditionalTree('or', andTrees));
  }

  emit('update:modelValue', result);
}

const cartesianProduct = arr => arr.reduce((a, b) => a.flatMap(x => b.map(y => [...x, y])), [[]]);

const getConditionalTree = (operator, options, recursionDepth = 0) => {

  if (!options.length || recursionDepth > 1000) {
    return null;
  }

  let result = { ...options[0] };

  if (options.length > 1) {

    const subTree = { [operator]: getConditionalTree(operator, options.slice(1), recursionDepth + 1) };

    Object.assign(result, subTree);
  }

  return result;
}

// watch for changes in hash and filtered filter data to update filter controls
watch([() => route.hash, () => props.filteredProperties], () => {
  hash.value = shopRouting.parseHash();
  updateFilterItems(props.filteredProperties);
}, { flush: 'pre', immediate: false });

const isFilterValueInHash = (urlParameter, urlValue) => !!hash.value.find(q => q.key === urlParameter && q.value.find(qq => qq === urlValue));

// updates hash with selected filter values
const filterApplied = (urlParameter, urlValue, add, replaceValue) => {

  // reset page to 1
  const modifiedHash = shopRouting.stringifyHash(shopRouting.updateValueInHash('p', '', false, true));

  // update filtered value in hash
  const hashObj = shopRouting.updateValueInHash(urlParameter, urlValue, add, replaceValue, modifiedHash);

  // navigate to the modified hash
  shopRouting.pushHash(hashObj);

}

const activeFilter = computed(() =>
  filterItems.value
    .filter(item => item.options && item.options.some(option => option.selected))
    .flatMap(item => {
      return item.options.filter(option => option.selected).map(option => {
        return {
          label: item.label,
          urlParameter: option.urlParameter ?? item.urlParameter,
          urlValue: option.urlValue,
          text: option.text,
          class: 'uppercase'
        };
      });
    })
    .concat(filterItems.value
      .filter(item => item.minValue !== item.minRange || item.maxValue !== item.maxRange)
      .map(item => {
        return {
          label: item.label,
          urlParameter: item.urlParameter,
          urlValue: `${item.minValue}-${item.maxValue}`,
          text: item.type === 'price' ? `${useLocale().formatPrice(item.minValue, false, undefined, true)} - ${useLocale().formatPrice(item.maxValue)}` : `${item.minValue} - ${item.maxValue} ${item.unit}`,
        };
      })
    ));

const clearFilter = () => {

  // reset page to 1
  let modifiedHash = shopRouting.updateValueInHash('p', '', false, true);

  activeFilter.value.forEach(item => {
    modifiedHash = shopRouting.updateValueInHash(item.urlParameter, item.urlValue, true, false, shopRouting.stringifyHash(modifiedHash));
  });

  shopRouting.pushHash(modifiedHash);
}

filterItems.value = buildFilter();

// set selected items in filter controls
if (hash.value?.length) {
  updateFilterItems();
}

const getFilterForAnalytics = () => {

  const result = shopRouting.parseHash().filter(q => q.key.indexOf(':')).sort((a, b) => a.key - b.key).map(item => {
    return `${item.key.split(':')[1]}:${item.value.sort((a, b) => a.key - b.key).map(item1 => item1.split(':')[1]).join(',')}`
  }).join(',');

  return result;
}

const showWaresClicked = () => {
  emit('scrollToWareList');

  useBaseAnalytics().pushEvent('user_interaction', {
    interaction_name: 'filter_show_products_click'
  });
}

onMounted(() => {
  document.body.style.overflowAnchor = 'none';
});

</script>

<style lang="postcss">
.list-move,
/* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}
</style>