<template>
    <NuxtLayout name="catalog">
        <div class="page-group">
            <div class="page-container">
                <MoleculesGlobalBreadcrumb :segments="parents" :show-all-segments="true"></MoleculesGlobalBreadcrumb>

                <div class="page-group__layout">

                    <div class="page-group__description" :class="{ 'desktop:hidden': (!entity.youtubeVideos?.length && !entity.annotation && !guideInfoPage) }">

                        <div class="flex flex-wrap gap-3 desktop:gap-4">

                            <!-- wrapper for video and description -->

                            <div class="flex gap-4 w-full desktop:flex-1">

                                <!-- video -->

                                <AtomsContainersCornerBorders v-if="entity.youtubeVideos.length" class="hidden items-center self-start aspect-video overflow-hidden desktop:flex desktop:w-[230px]">
                                    <MoleculesBlocksVideoItem :title="entity.youtubeVideos[0].title" :youtube-id="entity.youtubeVideos[0].youtubeId" :thumbnails="entity.youtubeVideos[0].thumbnails" :is-our-channel="entity.youtubeVideos[0].isOurChannel" class="absolute w-full" />
                                </AtomsContainersCornerBorders>

                                <!-- annotation -->

                                <div v-if="entity.annotation || entity.youtubeVideos.length" class="flex-1 flex flex-col justify-center gap-4 p-4 py-5 bg-neutral-100 desktop:px-6" :class="{ 'hidden desktop:flex': !entity.annotation }">

                                    <div class="hidden desktop:block" v-if="entity.youtubeVideos.length">
                                        <button class="mr-3 ext-lg tracking-normal text-left text-neutral-500 underline" @click="useVideo().play(entity.youtubeVideos[0].youtubeId, entity.youtubeVideos[0].title);">{{ entity.youtubeVideos[0].title }}</button>
                                        <AtomsTextLink v-if="entity.youtubeVideos[0].ware?.page.url" :to="entity.youtubeVideos[0].ware.page.url" class="pl-3 font-bold tracking-normal text-primary-500 underline border-l border-neutral-300" rel="nofollow">{{ $t('Zobrazit detail produktu') }}</AtomsTextLink>
                                    </div>

                                    <div v-if="entity.annotation" class="page-group__description__content" :class="{ 'page-group__description__content--expanded': annotationExpanded }" ref="annotation">
                                        <div v-html="entity.annotation"></div>
                                        <div v-if="!!entity.description" v-html="entity.description" class="page-group__description__content__html-description" :class="{ 'mt-5': !!entity.annotation }"></div>
                                        <button v-if="annotationOverflows && !annotationExpanded" class="absolute right-0 bottom-0 font-bold underline text-primary-500 bg-neutral-100 before:content-[''] before:text-right before:block before:absolute before:bottom-0 before:h-[1.5rem] before:-ml-[150px] before:w-[150px] before:bg-gradient-to-r before:from-transparent before:to-neutral-100 before:pointer-events-none" @click="expandAnnotation">{{ $t('Pokračovat') }}</button>
                                    </div>
                                </div>

                            </div>

                            <!-- guide -->

                            <div v-if="guideInfoPage" class="hidden flex-1 gap-3 items-center p-6 bg-neutral-100 tablet:flex desktop:flex-none desktop:gap-4 desktop:px-8">
                                <AtomsImagesIcon icon="question" size="2xl" />
                                <div>
                                    {{ $t('Nevíte si rady?') }}
                                    <AtomsTextLink :to="guideInfoPage.url" class="block font-bold underline text-primary-500" @click="guideClicked">{{ guideInfoPage.title }}</AtomsTextLink>
                                </div>
                            </div>

                            <!-- filter groups -->

                            <div v-if="entity.wares.itemsProperties.properties.some(property => property.type === 'FILTERGROUP')" class="page-group__tiles">
                                <OrganismsPanelsWareListTiles :full-properties="entity.wares.itemsProperties.properties" @scroll-to-ware-list="scrollToWareList" />
                            </div>

                            <!-- filter toggle -->

                            <div class="flex-1 flex gap-3 items-center justify-between p-6 bg-neutral-100 desktop:hidden">
                                <strong class="tracking-normal">{{ $t('Zobrazit filtr produktů') }}</strong>
                                <AtomsFormsCheckbox :display-as-switch="true" v-model="filterVisible" class="text-lg" />
                            </div>
                        </div>
                    </div>

                    <!-- bestsellers -->

                    <div v-if="bestsellers.length >= 5" class="page-group__bestsellers" data-pageelementname="element_category_bestsellers">
                        <strong class="block mt-1 mb-4 text-2xl tracking-normal">{{ $t('Nejprodávanější v kategorii') }}</strong>
                        <OrganismsListsWareList type="light" :with-slider-navigation="true" :items="bestsellers" :list-name="`Nejprodávanější - ${usePage().value.page.title}`" :group-id="entity.id" />
                    </div>

                    <!-- current filter -->

                    <div class="page-group__current-filter">
                        <OrganismsPanelsWareListFilter v-model="filter" :static-filter="staticFilter" :aside="false" :full-properties="entity.wares.itemsProperties" :full-tags="entity.wares.itemsTags" :filtered-properties="filteredItems?.wares.itemsProperties" :filtered-tags="filteredItems?.wares.itemsTags" :filtered-items-count="filteredItemsCount" @scroll-to-ware-list="scrollToWareList" />
                    </div>

                    <!-- warelist -->

                    <div class="page-group__warelist" ref="wareListAnchor">
                        <OrganismsListsWareList :items="items" :promotions="promotions" :with-sorting="true" :page-size="appConfig.wareList.itemsOnPage" :page="pageNumber" :total-items="filteredItemsCount" :total-full-items="entity.wareCount" :list-name="`Kategorie - ${usePage().value.page.title}`" :group-id="entity.id" :appending="appending" :loading="loading" class="mb-12" />
                    </div>

                    <!-- video -->

                    <div v-if="entity.youtubeVideos?.length" class="page-group__video">

                        <AtomsContainersCornerBorders class="flex items-center w-full aspect-video overflow-hidden tablet:w-[270px]">
                            <MoleculesBlocksVideoItem :title="entity.youtubeVideos[0].title" :youtube-id="entity.youtubeVideos[0].youtubeId" :thumbnails="entity.youtubeVideos[0].thumbnails" :is-our-channel="entity.youtubeVideos[0].isOurChannel" class="absolute w-full" />
                        </AtomsContainersCornerBorders>

                        <div class="flex-1 flex items-center p-7 bg-neutral-100">
                            <div>
                                <strong class="text-lg text-secondary-500">{{ $t('Přehrát video') }}:</strong>
                                <button class="block mt-2 text-lg tracking-normal text-left text-neutral-500 underline" @click="useVideo().play(entity.youtubeVideos[0].youtubeId, entity.youtubeVideos[0].title);">{{ entity.youtubeVideos[0].title }}</button>
                                <AtomsTextLink v-if="entity.youtubeVideos[0].ware?.page.url" :to="entity.youtubeVideos[0].ware.page.url" class="block mt-2 font-bold tracking-normal text-primary-500 underline">{{ $t('Zobrazit detail produktu') }}</AtomsTextLink>
                            </div>
                        </div>
                    </div>

                    <!-- magazine articles -->

                    <div v-if="entity.infoPages?.infoPages.items?.length" class="page-group__magazine">
                        <AtomsTextHeading text-class="!bg-neutral-100">{{ $t('Magazín články') }}</AtomsTextHeading>
                        <div class="flex flex-wrap gap-4 mt-6 tracking-normal">
                            <MoleculesBlocksMagazineItem v-for="article in entity.infoPages?.infoPages.items" :date-published="article.page.publishedAt" :date-created="article.created" :title="article.page.title" :url="article.page.url" :annotation="article.annotation" :time-to-read="article.timeToRead" :item-id="article.id" class="w-full !flex-none tablet:!flex-1" @click="magazineArticleClicked" />
                        </div>
                    </div>

                    <!-- recently viewed items -->

                    <div class="page-group__recent">
                        <LazyTemplatesRecentlyViewedItems />
                    </div>

                    <!-- usps -->

                    <div class="page-group__usps">
                        <OrganismsBlocksUsps item-class="!bg-neutral-100 desktop:aspect-[7/3]" />
                    </div>

                    <!-- groups -->

                    <div class="page-group__groups" :class="{ 'page-group__groups--without-subgroups': withoutSubgroups }">
                        <template v-if="!parentGroup">
                            <h1 class="font-bold text-3xl desktop:-ml-4 desktop:p-4 desktop:uppercase desktop:text-4xl">{{ entity.name }}</h1>
                        </template>
                        <template v-else>
                            <strong class="hidden pb-8 mb-6 border-b border-neutral-200 text-3xl uppercase desktop:block">
                                <AtomsTextLink :to="parentGroup.url" class="hover:no-underline">
                                    <AtomsImagesIcon icon="arrow-left-carousel" size="xs" class="mr-4" /><span class="underline">{{ parentGroup.name }}</span>
                                </AtomsTextLink>
                            </strong>
                            <h1 class="font-bold text-3xl">{{ entity.name }}</h1>
                        </template>
                        <ul v-if="!withoutSubgroups" class="flex flex-wrap gap-x-8 mt-6 desktop:-mb-3 desktop:block" :class="{ 'hidden desktop:block': siblingGroups?.length }">
                            <li v-for="item in entity.groups?.sort((a, b) => b.priority - a.priority)" class="page-group__subgroup" :class="{ 'page-group__subgroup--current': item.id === entity.id }">
                                <AtomsTextLink :to="item.page.url" class="page-group__subgroup__link">
                                    <AtomsImagesImage :src="`${useShopZone().value.staticResourcesRoot}/img/groups/tiles/${item.id}.png`" :alt="item.name" />
                                    <div>{{ item.name }}</div>
                                </AtomsTextLink>
                            </li>
                            <li v-for="item in siblingGroups?.sort((a, b) => b.priority - a.priority)" class="page-group__subgroup hidden desktop:block" :class="{ 'page-group__subgroup--current': item.id === entity.id }">
                                <AtomsTextLink :to="item.page.url" class="page-group__subgroup__link">
                                    <AtomsImagesImage :src="`${useShopZone().value.staticResourcesRoot}/img/groups/tiles/${item.id}.png`" :alt="item.name" />
                                    <div>{{ item.name }}</div>
                                </AtomsTextLink>
                            </li>
                        </ul>
                    </div>

                    <!-- filter -->

                    <div class="page-group__filter" :class="{ 'page-group__filter--expanded': filterVisible, 'desktop:-mt-10 desktop:!pt-0': withoutSubgroups }">
                        <OrganismsPanelsWareListFilter v-model="filter" :static-filter="staticFilter" :aside="true" :full-properties="entity.wares.itemsProperties" :full-tags="entity.wares.itemsTags" :filtered-properties="filteredItems?.wares.itemsProperties" :filtered-tags="filteredItems?.wares.itemsTags" :with-heading="!withoutSubgroups" @scroll-to-ware-list="scrollToWareList" />
                    </div>

                    <div class="clear-both"></div>
                </div>
            </div>
        </div>
    </NuxtLayout>
</template>
<script setup>

import { useDebounceFn } from '@vueuse/core'

import "assets/css/slider.css";
import "assets/css/carousel.css";

const appConfig = useAppConfig();
const entity = useEntity();
const route = useRoute();
const utils = useUtils();
const ui = useUI();
const i18n = useI18n();
const shopRouting = useShopRouting();
const locale = useLocale();

const hash = ref(shopRouting.parseHash());

const staticFilter = {
    groupId: { eq: entity.id }
}

const pageNumber = ref(1);

const filter = ref(staticFilter);

const itemsWithIndexes = entity.wares.wares.items.map((item, index) => {
    item.index = index + ((pageNumber.value ?? 1) - 1) * (appConfig.wareList.itemsOnPage ?? 1);
    return item;
});

const items = ref(itemsWithIndexes);

const promotions = entity.promotionsList?.promotions?.items;
const promotionsCount = entity.promotionsList?.promotions?.count;

const filteredItems = ref(null);
const bestsellers = entity.bestsellers.wares.items;

const filteredItemsCount = ref(entity.wareCount);
const filterVisible = ref(false);
const wareListAnchor = ref(null);

// skip root groups
let parents = entity.parents.filter(p => !appConfig.groupIdsToHideInBreadcrumb.includes(p.id)).sort((b, a) => a.level - b.level);

let parentGroup;
let siblingGroups;

// there is at least one parent group (other one is self)
if (parents.length > 1) {

    // get the last parent group (skip self)
    parentGroup = parents[parents.length - 2];

    // if the parent group is non-standard group, remove it from the parent list, so it doesn't show up in the breadcrumb
    if (parentGroup.group.idType !== 1) {
        parents = parents.filter(p => p.id !== parentGroup.id);
        parentGroup = parents[parents.length - 2];
    }
}

// if there are no child groups and the current groups doesn´t belong to vendors subtree or has any siblings, show them
if (entity.parentGroup?.length && !entity.groups?.length && entity.parentGroup[0].id !== appConfig.groupIdDleVyrobcu) {
    siblingGroups = entity.parentGroup[0].group.groups;
}

const withoutSubgroups = !entity.groups?.length && !siblingGroups?.length;

const appending = ref(false);
const loading = ref(false);

const guideInfoPage = entity.guideInfoPage?.page;

const guideClicked = () => {
    useBaseAnalytics().pushEvent('user_interaction', {
        interaction_name: 'group_guide_click'
    });
};

// reload items when the query changes and also when the initial load contains some parameters for filtering, sorting or paging
watch(() => filter.value, async (newValue, oldValue) => {

    updateWareList();

}, { flush: 'pre', deep: true });

let currentRequestNumber = 0;

const updateWareList = useDebounceFn(async () => {

    const thisRequestNumber = ++currentRequestNumber;

    hash.value = shopRouting.parseHash();

    const pageParameter = hash.value.find(q => q.key === 'p')?.value.toString() ?? "1";
    pageNumber.value = parseInt(pageParameter.split('-')[0]);
    const append = pageParameter.split('-').length === 2 && pageParameter.split('-')[1] === 'a';

    loading.value = true;

    let skip = (pageNumber.value - 1) * appConfig.wareList.itemsOnPage;

    if (promotionsCount) {
        skip -= pageNumber.value - 1;
    }

    const sort = hash.value.find(q => q.key === 'sort')?.value?.[0] ?? '';

    const updatedData = (await useApiFetch(`/api/wareList?skip=${skip}&sort=${sort}&currencyId=${locale.getLocale().currencyId}&languageId=${locale.getLocale().languageId}`,
        {
            method: 'POST',
            body: {
                filter: filter.value,
            }
        })).wareList;

    if (thisRequestNumber === currentRequestNumber) {

        updatedData.wares.items.forEach((item, index) => {
            item.index = index + ((pageNumber.value ?? 1) - 1) * (appConfig.wareList.itemsOnPage ?? 1);
        });

        if (append) {
            items.value = items.value.concat(updatedData.wares.items);
            appending.value = true;

            setTimeout(() => {
                appending.value = false;
            }, 100);
        }
        else {
            items.value = updatedData.wares.items;
        }

        filteredItems.value = updatedData;
        filteredItemsCount.value = updatedData.wares.count;

        loading.value = false;

        if (useState('scrollToCurrentFilter').value && wareListAnchor.value) {
            ui.scrollToElement(wareListAnchor.value, 90);
            useState('scrollToCurrentFilter').value = false;
        }
    }
}, 100);

const scrollToWareList = () => {
    ui.scrollToElement(wareListAnchor.value, 30);
}

//#region annotation

const annotation = ref(null);
const annotationExpanded = ref(false);
const annotationOverflows = ref(false);

const checkOverflow = () => {
    annotationOverflows.value = annotation.value && annotation.value.scrollHeight > annotation.value.clientHeight;
};

const expandAnnotation = () => {
    annotationExpanded.value = true;

    useBaseAnalytics().pushEvent('user_interaction', {
        interaction_name: 'group_annotation_expanded'
    });
};

//#endregion

const magazineArticleClicked = () => {
    useBaseAnalytics().pushEvent('user_interaction', {
        interaction_name: 'group_magazine_article_click'
    });
};

const ogImage = useState('ogImage', () => null);

if (entity.parents.some(parentGroup => parentGroup.id === appConfig.groupIdDleVyrobcu)) {

    const imagePath = `${useShopZone().value.staticResourcesRoot}/img/groups/vendors0/${entity.id}.png`;

    if (process.server) {
        const result = (await fetch(imagePath)).ok;

        if (result) {
            ogImage.value = imagePath;
        }
    }
}
else if (items.value[0]?.image?.id) {
    ogImage.value = utils.getWareImageUrl(items.value[0].image.id, items.value[0].name, "or");
}

if (ogImage.value) {
    useSeoMeta({
        ogImage: ogImage.value
    });
}

watch(() => annotation.value, () => {
    checkOverflow();
});

onMounted(() => {
    window.addEventListener('resize', checkOverflow);
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', checkOverflow);
});


</script>
<style lang="postcss">
.page-group {

    --sidebar-width: 360px;

    &__layout {
        @apply flex flex-col mb-12 desktop:block;
    }

    &__groups,
    &__filter {
        @apply mb-3 tracking-normal bg-neutral-100 bg-neutral-100 desktop:w-[var(--sidebar-width)] desktop:-ml-4 desktop:mb-8;
    }

    &__video,
    &__description,
    &__bestsellers,
    &__current-filter,
    &__warelist,
    &__magazine,
    &__recent,
    &__usps {
        @apply float-right desktop:w-[calc(100%-var(--sidebar-width))];
    }

    &__groups {
        @apply p-6 order-[0] desktop:py-10;

        &--without-subgroups {
            @apply desktop:mb-0;
        }
    }

    &__video {
        @apply order-[5] flex flex-wrap mb-3 gap-3 tablet:order-[1] desktop:hidden;
    }

    &__description {
        @apply order-[1] mb-3 tablet:order-[2] desktop:mb-4;

        &__content {

            @apply relative inline-block leading-6 max-h-[4.5rem] overflow-hidden tablet:max-h-[3rem];

            &__html-description {

                p {
                    @apply my-4 text-justify;
                }

                h2,
                h3 {
                    @apply text-xl tracking-normal font-bold;
                }

                a {
                    @apply text-primary-500 underline;
                }
            }

            &--expanded {
                animation: slide-toggle-simple 0.6s;
                animation-fill-mode: forwards;
            }
        }
    }

    &__tiles {
        @apply w-full;
    }

    &__filter {
        @apply max-h-0 overflow-hidden order-[2] tablet:order-[3] desktop:max-h-full desktop:overflow-visible;

        &--expanded {
            animation: slide-toggle 0.6s;
            animation-fill-mode: forwards;
        }
    }

    &__bestsellers {
        @apply order-[3] mb-3 desktop:mb-4 tablet:order-[4];
    }

    &__current-filter {
        @apply hidden mb-3 tablet:order-[5] tablet:block;
    }

    &__warelist {
        @apply order-[4] tablet:order-[6];
    }

    &__magazine {
        @apply order-[7] mb-3 p-8 bg-neutral-100 desktop:mb-6;
    }

    &__recent {
        @apply order-[8];
    }

    &__usps {
        @apply mt-6 order-[9];
    }

    &__subgroup {
        @apply w-full transition-colors tablet:w-[calc(50%-1rem)] desktop:w-auto desktop:-mr-6;

        &__link {
            @apply flex gap-4 items-center text-black border-t border-neutral-200 hover:no-underline desktop:mr-6;

            img {
                @apply w-[40px] max-h-[40px] object-contain m-4;
            }

            div {
                @apply flex-1 py-6 pr-6 text-xl desktop:-mr-6;
            }
        }

        &:hover,
        &--current {
            @apply bg-neutral-400 text-neutral-50;

            .page-group__subgroup__link {
                @apply !mt-[1px] text-neutral-50 !border-t-0;
            }
        }

        &:first-of-type &__link {
            @apply border-t-0 mt-[1px];
        }

        @screen tablet {
            &:nth-of-type(2) &__link {
                @apply border-t-0 mt-[1px];
            }
        }

        @screen desktop {
            &:nth-of-type(2) &__link {
                @apply border-t mt-0;
            }
        }
    }

    .warelist__filter {
        @apply p-6;
    }
}
</style>