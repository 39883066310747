<template>
    <div class="pb-2 mb-6 border-b border-neutral-200">
        <div class="uppercase font-bold tracking-normal text-lg">{{ props.label }}</div>

        <!-- range slider -->

        <div class="px-4 py-7" v-if="props.minRange || props.maxRange" data-pagearea="filter_range">
            <div class="flex flex-wrap gap-2 justify-between mb-4">
                <span class="px-2 py-1 text-sm border border-neutral-400 text-neutral-400 bg-neutral-50"><input type="number" class="w-[50px] border-0 text-right text-neutral-400 bg-neutral-50" :min="props.minRange" :max="props.maxRange" v-model="props.minValue" @change="updateRange($event, 0)" /> {{ props.unit }}</span>
                <span class="px-2 py-1 text-sm border border-neutral-400 text-neutral-400 bg-neutral-50"><input type="number" class="w-[50px] border-0 text-right text-neutral-400 bg-neutral-50" :min="props.minRange" :max="props.maxRange" v-model="props.maxValue" @change="updateRange($event, 1)" /> {{ props.unit }}</span>
            </div>
            <div class="px-2">
                <Slider :min="props.minRange" :tooltips="false" :max="props.maxRange" v-model="selectedRange" @update="updateRange($event)" />
            </div>
        </div>

        <!-- checkbox options -->

        <div class="p-4" v-if="props.options?.length">

            <!-- search with results -->

            <AtomsFormsTextInput v-if="withSearch" :placeholder="`${i18n.t('Vyhledat')}...`" type="search" v-model="searchText" class="w-full mb-4 bg-white" />
            <template v-if="searchResults">
                <AtomsFormsCheckbox v-for="item in searchResults" class="!flex mb-2" :class="{ 'line-through': item.unavailable, 'cursor-not-allowed': item.unavailable }" v-model="item.selected" @update:model-value="updateFilter(item.urlParameter, item.urlValue, $event, !!props.singleOptionOnly)" :disabled="item.unavailable" :title="item.unavailable ? defaultUnavailableTitle : ''"><img class="warelist__filter__flag" v-if="item.flag" :src="`/img/flags/${item.flag.toLowerCase()}.svg`" :alt="item.flag" />{{ item.text }}</AtomsFormsCheckbox>
            </template>

            <!-- regular checkboxes -->

            <template v-else>
                <template v-for="(item, index) in props.options">
                    <AtomsFormsCheckbox v-if="index <= showMoreBoundaryIndex" class="!flex mb-2" :class="{ 'line-through': item.unavailable, 'cursor-not-allowed': item.unavailable }" v-model="item.selected" @update:model-value="updateFilter(item.urlParameter, item.urlValue, $event, !!props.singleOptionOnly)" :disabled="item.unavailable" :title="item.unavailable ? defaultUnavailableTitle : ''"><img class="warelist__filter__flag" v-if="item.flag" :src="`/img/flags/${item.flag.toLowerCase()}.svg`" :alt="item.flag" />{{ item.text }}</AtomsFormsCheckbox>
                </template>
                <div class="warelist__filter__more-options" :class="{ 'warelist__filter__more-options--expanded': expanded || props.showAllOptionsDefault }">
                    <template v-for="(item, index) in props.options">
                        <AtomsFormsCheckbox v-if="index > showMoreBoundaryIndex" class="!flex mb-2" :class="{ 'line-through': item.unavailable, 'cursor-not-allowed': item.unavailable }" v-model="item.selected" @update:model-value="updateFilter(item.urlParameter, item.urlValue, $event, !!props.singleOptionOnly)" :disabled="item.unavailable" :title="item.unavailable ? defaultUnavailableTitle : ''"><img class="warelist__filter__flag" v-if="item.flag" :src="`/img/flags/${item.flag.toLowerCase()}.svg`" :alt="item.flag" />{{ item.text }}</AtomsFormsCheckbox>
                    </template>
                </div>
                <button v-if="props.options?.length > (showMoreBoundaryIndex + 1) && !props.showAllOptionsDefault" class="!mt-2 text-sm" @click="expand">
                    <template v-if="!expanded">
                        <span class="underline">{{ $t('Zobrazit všechny') }}</span>
                        (+{{ props.options.length - (showMoreBoundaryIndex + 1) }})
                        <AtomsImagesIcon icon="arrow-right-carousel" class="inline-block ml-1 scale-[0.6] rotate-90" />
                    </template>
                    <template v-else>
                        {{ $t('Skrýt') }}
                        <AtomsImagesIcon icon="arrow-right-carousel" class="inline-block ml-1 scale-[0.6] -rotate-90" />
                    </template>
                </button>
            </template>
        </div>
    </div>
</template>
<script setup>

import Slider from '@vueform/slider'
import { useDebounceFn } from '@vueuse/core'

const props = defineProps({
    label: String,
    urlParameter: String,
    range: String,
    minValue: Number,
    maxValue: Number,
    minRange: Number,
    maxRange: Number,
    unit: String,
    options: Array,
    showAllOptionsDefault: Boolean,
    singleOptionOnly: Boolean,
    withSearch: Boolean
});

const emit = defineEmits(['applyFilter']);

const i18n = useI18n();
const utils = useUtils();

const defaultUnavailableTitle = i18n.t('Žádné produkty v aktuálním výběru neodpovídají tomuto filtru. Pro zahrnutí této hodnoty do filtru je nutné vyřadit některé stávající filtrované hodnoty.');

const showMoreBoundaryIndex = computed(() => {
    const preferredCount = props.options.filter(option => option.preferred).length;
    return preferredCount === 0 ? 4 : preferredCount - 1;
});

const minValue = ref(props.minValue);
const maxValue = ref(props.maxValue);
const expanded = ref(false);
const searchText = ref('');
const searchResults = ref(null);

const selectedRange = computed(() => {
    return [props.minValue, props.maxValue];
});

const expand = () => expanded.value = !expanded.value;

const updateRange = ($event, index) => {
    if ($event instanceof Array) {
        minValue.value = $event[0];
        maxValue.value = $event[1];
    }
    else if ($event?.target && typeof index === 'number') {
        if (index === 0) {
            minValue.value = parseInt($event.target.value);

            if (minValue.value >= maxValue.value) {
                maxValue.value = minValue.value;
            }
        }
        else if (index === 1) {
            maxValue.value = parseInt($event.target.value);

            if (minValue.value >= maxValue.value) {
                minValue.value = maxValue.value;
            }
        }
    }

    const urlValue = `${minValue.value}-${maxValue.value}`;

    if (minValue.value <= props.minRange && maxValue.value >= props.maxRange) {
        // selected range equals boundaries => remove filter
        updateFilter(null, urlValue, false, true);
    }
    else {
        updateFilter(null, urlValue, true, true);
    }
}

const updateFilter = (urlParameter, urlValue, add, replaceValue) => {
    emit('applyFilter', urlParameter ?? props.urlParameter, urlValue, !add, replaceValue);

    useBaseAnalytics().pushEvent('user_interaction', {
        interaction_name: 'filter_select'
    });
}

let hasSearched = false;

const search = useDebounceFn(async () => {

    if (!hasSearched) {
        useBaseAnalytics().pushEvent('user_interaction', {
            interaction_name: 'filter_search'
        });
        hasSearched = true;
    }

    if (searchText.value.length) {
        const searchTextProcessed = utils.removeDiacritics(searchText.value.toLocaleLowerCase().trim());

        searchResults.value = props.options.filter(q => utils.removeDiacritics(q.text.toLowerCase()).indexOf(searchTextProcessed) > -1);
    }
    else {
        searchResults.value = null;
    }
}, 200);

watch(searchText, () => {
    search();
});

</script>
<style lang="postcss">
.warelist__filter__more-options {
    @apply max-h-0 overflow-hidden;

    &--expanded {
        animation: slide-toggle 0.6s;
        animation-fill-mode: forwards;
    }
}

.warelist__filter__flag {
    @apply inline-block h-5 mr-4 align-top border border-secondary-500 rounded-md;
}
</style>