<template>
  <div class="warelist__tiles" data-pagearea="tiles_filter" ref="tilesAnchor">

    <strong v-if="!isParentTileSelected" class="block mt-2 text-2xl tracking-normal">{{ $t('Značka vaší zbraně') }}</strong>
    <strong v-else class="block mt-2  text-2xl tracking-normal">{{ $t('Model vaší zbraně') }}</strong>

    <p class="mt-2 mb-4">{{ $t('Zobrazte si pouze zboží kompatibilní s vaší zbraní.') }}</p>

    <div class="warelist__tiles__items">
      <button v-for="item in visibleTiles.slice(0, maxItemsBeforeCollapse)" :to="item.url" class="warelist__tiles__item" :class="{ 'warelist__tiles__item--selected': item.selected }" @click="selectTile(item)">
        <span class="flex items-center gap-6">
          <AtomsImagesImage v-if="!item.parentId" :src="`${useShopZone().value.staticResourcesRoot}/shopFiles/tileFilters/${item.id}.png`" :alt="item.name" class="w-10 aspect-square object-contain" />
          {{ item.name }}
        </span>
        <AtomsImagesIcon v-if="!item.selected" icon="arrow-right-carousel" />
        <AtomsImagesIcon v-if="item.selected" icon="cross" />
      </button>
    </div>

    <MoleculesContainersSlideToggle :expanded="tilesExpanded">
      <div class="warelist__tiles__items">
        <button v-for="item in visibleTiles.slice(maxItemsBeforeCollapse)" :to="item.url" class="warelist__tiles__item" :class="{ 'warelist__tiles__item--selected': item.selected }" @click="selectTile(item)">
          <span class="flex items-center gap-6">
            <AtomsImagesImage v-if="!item.parentId" :src="`${useShopZone().value.staticResourcesRoot}/shopFiles/tileFilters/${item.id}.png`" :alt="item.name" class="w-10 aspect-square object-contain" />
            {{ item.name }}
          </span>
          <AtomsImagesIcon v-if="!item.selected" icon="arrow-right-carousel" />
          <AtomsImagesIcon v-if="item.selected" icon="cross" />
        </button>
      </div>
    </MoleculesContainersSlideToggle>

    <button v-if="isParentTileSelected" class="block w-full my-4 font-bold text-center text-primary-500 tracking-normal underline desktop:w-auto desktop:text-left" @click="reset">{{ $t('Zpět na výrobce vaší zbraně') }}</button>

    <template v-if="!tilesExpanded && visibleTiles.length > maxItemsBeforeCollapse">
      <MoleculesButtonsButton v-if="!isParentTileSelected" :ghost="true" class="w-full -mt-2 mb-4 tablet:mt-0 desktop:hidden" @click="tilesExpanded = true">{{ $t('Zobrazit všechny značky') }}</MoleculesButtonsButton>
      <MoleculesButtonsButton v-else :ghost="true" class="w-full mb-4 desktop:hidden" @click="tilesExpanded = true">{{ $t('Zobrazit všechny modely') }}</MoleculesButtonsButton>
    </template>

  </div>
</template>

<script setup>

import { useBreakpoints, useElementBounding } from '@vueuse/core';

const props = defineProps({
  fullProperties: Object
});

const appConfig = useAppConfig();
const route = useRoute();
const shopRouting = useShopRouting();
const utils = useUtils();
const ui = useUI();
const view = useBreakpoints(appConfig.breakpoints);

const emit = defineEmits(['scrollToWareList']);

const maxItemsBeforeCollapse = 8;

const tilesAnchor = ref(null);
const tilesExpanded = ref(false);

const hash = ref(shopRouting.parseHash());

const allTiles = reactive(props.fullProperties.filter((item) => item.type === 'FILTERGROUP').flatMap((item) => {
  return item.values.flatMap(option => {
    return option.filters.flatMap(filterGroup => {

      const urlValue = utils.slugify(filterGroup.name);

      return [
        {
          id: filterGroup.id,
          parentId: filterGroup.parent.id,
          name: filterGroup.name,
          urlParameter: `fg-${filterGroup.parent.id}`,
          urlValue: urlValue
        },
        {
          id: filterGroup.parent.id,
          name: filterGroup.parent.name,
          urlParameter: `fgp-${filterGroup.parent.id}`,
          urlValue: '1'
        }
      ];
    })
  })
}).reduce((acc, item) => {

  const existingItem = acc.find(q => q.urlParameter === item.urlParameter && q.urlValue === item.urlValue);

  if (!existingItem) {
    acc.push(item);
  }

  return acc;
}, []));

const visibleTiles = computed(() => {

  // child tile selected, show its siblings

  if (allTiles.some(q => q.selected && q.parentId)) {
    return allTiles.filter(q => q.parentId === allTiles.find(qq => qq.selected && qq.parentId).parentId);
  }

  // parent tile selected, show its children

  else if (allTiles.some(q => q.selected && !q.parentId)) {
    return allTiles.filter(q => q.parentId === allTiles.find(qq => qq.selected && !qq.parentId).id);
  }

  // no tile selected, show all parent tiles

  else {
    return allTiles.filter(q => !q.parentId);
  }
});

const isParentTileSelected = computed(() => allTiles.some(q => q.selected && !q.parentId));

const isFilterValueInHash = (urlParameter, urlValue) => !!hash.value.find(q => q.key === urlParameter && q.value.find(qq => qq === urlValue));

const selectTile = tile => {

  // child tile, update hash to filter wareList

  if (tile.parentId) {

    const modifiedHash = shopRouting.stringifyHash(shopRouting.updateValueInHash('p', '', false, true));

    // update filtered value in hash
    const hashObj = shopRouting.updateValueInHash(tile.urlParameter, tile.urlValue, false, false, modifiedHash);

    // navigate to the modified hash
    shopRouting.pushHash(hashObj);

    emit('scrollToWareList');
  }

  // parent tile - set it as selected but don´t update wareList filtering and results

  else {

    // update filtered value in hash
    const hashObj = shopRouting.updateValueInHash(tile.urlParameter, tile.urlValue, true, false);

    // navigate to the modified hash
    shopRouting.pushHash(hashObj);

    tilesExpanded.value = false;

    if (!view.greaterOrEqual('desktop').value) {
      ui.scrollToElement(tilesAnchor.value, 70);
    }
  }
}

const reset = () => {

  // reset hash to show all parent tiles

  let modifiedHash = shopRouting.stringifyHash(shopRouting.updateValueInHash('p', '', false, true));

  allTiles.filter(q => q.selected).forEach(tile => {
    console.log(tile.urlParameter);
    modifiedHash = shopRouting.stringifyHash(shopRouting.updateValueInHash(tile.urlParameter, tile.urlValue, true, false, modifiedHash));
  });

  useRouter().push(modifiedHash);

  if (!view.greaterOrEqual('desktop').value) {
      ui.scrollToElement(tilesAnchor.value, 70);
    }
}

onMounted(() => {
  watch(() => route.hash, () => {
    hash.value = shopRouting.parseHash();

    allTiles.forEach(tile => {
      tile.selected = isFilterValueInHash(tile.urlParameter, tile.urlValue);
    });

  }, { flush: 'pre', immediate: true });
});

</script>

<style lang="postcss">
.warelist__tiles {

  &__items {
    @apply grid gap-4 grid-cols-1 tablet:grid-cols-3 desktop:grid-cols-4;
  }

  .slide-toggle {
    @apply desktop:max-h-none desktop:opacity-100;

    .warelist__tiles__items {
      @apply mt-4;
    }
  }

  &__item {
    @apply flex items-center justify-between w-full p-6 text-lg border border-neutral-500;

    &--selected {
      @apply border-neutral-200 bg-neutral-200;
    }
  }
}
</style>